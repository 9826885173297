import {HttpErrorResponse} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {Hub} from 'aws-amplify/utils';
import {Constants} from '../constant/constants';
import {UpdateUserInfo, User} from '../model/user';
import {AuthenticationService} from '../service/authentication.service';
import {SharedService} from '../service/shared.service';
import {UserManagementService} from '../service/user-management.service';
import {LoadingIndicatorService} from "../service/loading-indicator.service";
import { OrderService } from '../service/order.service';
import { CountryRegionService } from '../service/country-region.service';
import {UserRecordLoginSession} from "../model/user-record-login-session";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-admin-home',
  templateUrl: './admin-home.component.html',
  styleUrls: ['./admin-home.component.scss'],
  providers:[DatePipe]
})
export class AdminHomeComponent implements OnInit {
  loggedInUser: User;
  userFound=true;

  constructor(private _authenticationService: AuthenticationService,
              private sharedService: SharedService,
              private userManagementService: UserManagementService,
              public loaderService: LoadingIndicatorService,
              private orderService: OrderService,
              private countryRegionService: CountryRegionService,
             private datePipe: DatePipe
  ) {
  }

  ngOnInit(): void {
    Hub.listen('auth', this.hubListener);
    this.sharedService.userInfo$.subscribe(user => {
      if (user) {
        this.loggedInUser = user;
        this.countryRegionService.loadCountries();
        this.getVTHopOscConfigCodes();
      }
    });
  }

  fetchUserDetails(cognitoUser){
    this.loaderService.isLoading.next(true);
    this.userManagementService.getUserByEmail(cognitoUser.emailAddress).subscribe((currentUser: User) => {
      Object.assign(currentUser, cognitoUser);
      sessionStorage.setItem(Constants.AUTH_TOKEN, btoa(JSON.stringify(currentUser)));
      this.sharedService.onLoggedInUser(currentUser);
      this.loaderService.isLoading.next(false);
      this.userFound=true;
      this.loaderService.isLoading.next(false);
      this.recordUserLoginAttempt(currentUser);
    }, (getUserError: HttpErrorResponse) => {
      this.loaderService.isLoading.next(false);
      if (getUserError.status === 404) {
        console.log('user not found ');
        this.userFound=false;
        this.loaderService.isLoading.next(false);
      }
    });
  }

  recordUserLoginAttempt(currentUser){
    const userRecordLoginSession: UserRecordLoginSession=new UserRecordLoginSession();
    userRecordLoginSession.userLoginTimestamp=this.datePipe.transform(new Date(), 'yyyy-MM-ddTHH:mm:ss.SSS',"UTC")+"+00:00";
    userRecordLoginSession.userId=currentUser.userId;
    userRecordLoginSession.createdBy=currentUser.emailId;
    this.userManagementService.recordUserLoginAttempt(userRecordLoginSession).subscribe(data=>{
      console.log("userRecordLoginSession = "+data);
    });
  }

  constructMessage(loggedInUser: any) {
    if(!(loggedInUser?.oscDetails?.length > 0) && !(loggedInUser?.role?.roleName)) {
      return 'Role Name and Originating System Code or Program Group'
    } else if(!(loggedInUser?.oscDetails?.length > 0)) {
      return 'Originating System Code or Program Group'
    } else if(!(loggedInUser?.role?.roleName)) {
      return 'Role Name'
    } else {
      return '';
    }
  }

  updateUserRole(user: User) {
    const roleName = user.role.roleName;
    const keys = Object.keys(sessionStorage);
    let userDataKey;
    for (const key of keys) {
      if (key.indexOf('.userData') >= 0) {
        userDataKey = key;
        break;
      }
    }
    if (userDataKey) {
      const userData = JSON.parse(sessionStorage.getItem(userDataKey));
      const userAttributes = userData.UserAttributes;
      let userAttributeInfo;
      for (const userAttribute of userAttributes) {
        if (userAttribute.Name === 'custom:memberof') {
          userAttributeInfo = userAttribute;
        }
      }
      if (userAttributeInfo) {
        if (userAttributeInfo.Value.toLowerCase() !== roleName.toLowerCase()) {
          // update the user
          const updateUserInfo = this.getUpdateUserInfo(user, userAttributeInfo.Value);
          this.loaderService.isLoading.next(true);
          const obs = this.userManagementService.updateUserDetails(updateUserInfo);
          obs.subscribe(data => {
            this.fetchUserDetails({emailAddress: data.emailId} );
          }, err => {
            console.log('error while updating the user info = ' + err);
            this.loaderService.isLoading.next(false);
          });
        }
      }
    }
  }

  getVTHopOscConfigCodes() {
    this.orderService.getOSCCodesByHop('vthop').subscribe({
      next: (data) => {
        this.sharedService.vtHopOscConfigCodesData(data);
      },
      error: (err: any) => {
        console.log('err = ' + JSON.stringify(err));
      },
    });
  }

  getUpdateUserInfo(user: User, updRoleName: string) {
    const updateUserInfo = new UpdateUserInfo();
    updateUserInfo.accountType = user.accountType;
    updateUserInfo.emailId = user.emailId;
    updateUserInfo.firstName = user.firstName;
    updateUserInfo.guid = user.guid;
    updateUserInfo.lastName = user.lastName;
    updateUserInfo.middleName = user.middleName || '';
    updateUserInfo.roleName = updRoleName.toLowerCase();
    updateUserInfo.state = user.state;
    updateUserInfo.updatedBy = user.userLoginId;
    updateUserInfo.userId = Number( user.userId);
    updateUserInfo.userLoginId = user.userLoginId;

    return updateUserInfo;
  }

  hubListener = (data) => {
    switch (data.payload.event) {
      case "signedIn":
        this.loaderService.isLoading.next(true);
        this._authenticationService.setUpIdentity().subscribe((cognitoUser: User) => {
          if (!cognitoUser) {
            this._authenticationService.invalidateSession(Constants.LOGOUT_REASON_LOGOUT);
          }
          this.loaderService.isLoading.next(false);
          this.fetchUserDetails(cognitoUser);
        }, error => {
          this.loaderService.isLoading.next(false);
          console.log('Error in setting up user identity in SP', error);
          this._authenticationService.invalidateSession(Constants.LOGOUT_REASON_LOGOUT);
        });
        break;
      case 'signedOut':
        this.sharedService.onLoggedInUser(null);
        this.sharedService.onProfilePermission(null);
        break;
      case 'tokenRefresh':
        break;
      case 'tokenRefresh_failure':
        console.error('token refresh failed');
        break;
    }
  }
}
