import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable, throwError} from 'rxjs';
import {ExternalUser, UpdateUserInfo, User} from '../model/user';
import {catchError} from 'rxjs/operators';
import {Constants} from '../constant/constants';
import {UserSearchInfo} from '../model/user-search-info';
import { UserUpdatedInfo } from '../model/update-users-role-business';
import {UserAuditInfo} from "../model/user-audit-info";
import {UserRecordLoginSession} from "../model/user-record-login-session";

@Injectable({
  providedIn: 'root'
})
export class UserManagementService {

  constructor(private http: HttpClient) {
  }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  /**Gets all the users of the system. */
  getAllUsers(): Observable<User[]> {
    return this.http.get<User[]>(`${environment.ADMIN_API_HOSTNAME}/config/users/getAllUsers`);
  }

  getAllUsersByUserId(userId): Observable<User[]> {
    return this.http.get<User[]>(`${environment.ADMIN_API_HOSTNAME}/config/users/getAllUsers?userId=${userId}`);
  }

  /** Gets the User by User Login Id. */
  getUserByLoginId(userLoginId): Observable<User> {
    return this.http.get<User>(`${environment.ADMIN_API_HOSTNAME}/config/users/userLoginId/${userLoginId}`)
      .pipe(catchError((error: HttpErrorResponse) => {
        return throwError(error);
      }));
  }

  /** Gets the User by User Email. */
  getUserByEmail(email): Observable<User> {
    return this.http.get<User>(`${environment.ADMIN_API_HOSTNAME}/config/users/emailId/${email}`)
      .pipe(catchError((error: HttpErrorResponse) => {
        return throwError(error);
      }));
  }

  provisionUser(user: User): Observable<User> {
    const createUserReq: User = {} as User;
    const isExternal = user?.roleName?.toLocaleLowerCase()?.indexOf('ext') > -1;
    createUserReq.accountType = isExternal ? Constants.EXTERNAL : Constants.INTERNAL;
    createUserReq.createdBy = Constants.EPMS_System;
    createUserReq.emailId = user.emailAddress;
    createUserReq.firstName = user.firstName;
    createUserReq.lastName = user.lastName;
    createUserReq.middleName = user.middleName;
    createUserReq.guid = user.guid;
    createUserReq.roleName = user.roleName?.toLocaleLowerCase();
    createUserReq.state = Constants.ACTIVE;
    createUserReq.userLoginId = isExternal ? user.preferredName : user.emailAddress.split('@')[0];;
    return this.http.post<User>(`${environment.ADMIN_API_HOSTNAME}/config/users/createUser`, JSON.stringify(createUserReq), this.httpOptions)
      .pipe(catchError((error: HttpErrorResponse) => {
        return throwError(error);
      }));
  }
  updateUserDetails(updateUserInfo: UpdateUserInfo) {
    const url = `${environment.ADMIN_API_HOSTNAME}/config/users/updateUser`;
    return this.http.put<User>(url, updateUserInfo, this.httpOptions);
  }

  searchUsers(userSearchInfo: UserSearchInfo): Observable<User[]> {
    const url = `${environment.ADMIN_API_HOSTNAME}/config/users/searchUsers`;
    return this.http.post<User[]>(url, userSearchInfo, this.httpOptions);
  }


  createExternalUser(user: ExternalUser): Observable<ExternalUser> {
    return this.http.post<ExternalUser>(`${environment.ADMIN_API_HOSTNAME}/config/idaas/createUser`, user)
      .pipe(catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      }));
  }

  updateUsersOscRole(userUpdatedInfo: UserUpdatedInfo): Observable<any> {
    const url = `${environment.ADMIN_API_HOSTNAME}/config/users/updateUserOscRole`;
    return this.http.post(url, userUpdatedInfo, this.httpOptions);
  }
  getUserAuditDetails(userId:string):Observable<UserAuditInfo[]>{
    const url=`${environment.ADMIN_API_HOSTNAME}/config/users/getUserAudit/${userId}`;
    return this.http.get<UserAuditInfo[]>(url, this.httpOptions);
  }

  recordUserLoginAttempt(userRecordLoginSession: UserRecordLoginSession): Observable<any> {
    const url = `${environment.ADMIN_API_HOSTNAME}/config/users/recordLoginAttempt`;
    return this.http.post(url, userRecordLoginSession, this.httpOptions);
  }

  getUserRecordLoginDetails(userId:string):Observable<UserRecordLoginSession[]>{
    const url=`${environment.ADMIN_API_HOSTNAME}/config/users/recordLoginAttempt/${userId}`;
    return this.http.get<UserRecordLoginSession[]>(url, this.httpOptions);
  }
}
